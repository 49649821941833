<template>
  <div class="max-w-5xl p-5 mx-auto">
    <div v-if="query.payment_status === 'paid'">
      <h1 class="font-semibold text-secondary text-2xl">
        Tu pedido se ha realizado con éxito.
      </h1>
      <p class="mt-3 mb-6 text-primary">
        Para ver mas detalles de tus pedidos entra en tu cuenta en la sección de
        Mis Pedidos.
      </p>
    </div>
    <div v-else-if="query.payment_status === 'pending_payment'">
      <h1 class="text-2xl uppercase font-bold text-secondary font-secondary">
        Transferencia bancaria SPEI
      </h1>
    </div>
    <div v-else>
      <p>Ocurrio un error durante el proceso de pago.</p>
    </div>

    <div v-if="order != null">
      <div
        v-for="item in order.line_items"
        :key="item.id"
        class="grid grid-cols-2 sm:grid-cols-3 border-b gap-1 py-8 md:grid-cols-4 items-center"
      >
        <div class="flex col-span-2 gap-4 items-center">
          <div
            class="w-32 h-32 rounded-lg border"
            :style="{
              background: 'url(' + item.equipment.thumbnail + ')',
              'background-repeat': 'no-repeat',
              'background-size': 'cover',
              'background-position': 'center center',
            }"
          >
          </div>
          <div>
            <strong class="text-secondary uppercase font-semibold">Modelo:</strong>
            <h2 class="mb-2 uppercase font-bold text-xl text-primary font-secondary">{{ item.equipment.name }} ({{ item.equipment.year }})</h2>
          </div>
        </div>
        <div class="justify-self-start sm:justify-self-end md:justify-self-center">
          Fechas Seleccionadas
          <table class="">
            <tbody class="uppercase text-gray-600 text-sm">
              <tr>
                <td class="font-semibold pr-3">Inicia:</td>
                <td class="text-right">{{ date(item.start_date) }}</td>
              </tr>
              <tr>
                <td class="font-semibold pr-3">Termina:</td>
                <td class="text-right">{{ date(item.end_date) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-start-2 sm:col-start-3 md:col-start-auto">
          <table class="text-secondary uppercase font-semibold h-full sm:h-auto w-full">
            <tbody>
              <tr>
                <td>Flete:</td>
                <td class="text-right">{{ currency(item.delivery_fee / 100) }}</td>
              </tr>
              <tr>
                <td>Seguro:</td>
                <td class="text-right">{{ currency(item.insurance_fee / 100) }}</td>
              </tr>
              <tr>
                <td>Renta:</td>
                <td class="text-right">{{ currency(item.subtotal / 100) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-8 items-end mt-8">
        <div class="">
          <div class="flex items-center">
            <r-icon type="location" color class="h-4 mr-3 text-primary" />
            {{ order.metadata.location.value }}
          </div>
          <table class="w-full">
            <tbody>
              <tr>
                <td class="font-semibold uppercase text-gray-600">Colonia:</td>
                <td class="text-right pl-2">Centro</td>
              </tr>
              <tr>
                <td class="font-semibold uppercase text-gray-600">Calle:</td>
                <td class="text-right pl-2">{{ order.metadata.street }}</td>
              </tr>
              <tr>
                <td class="font-semibold uppercase text-gray-600">No. exterior:</td>
                <td class="text-right pl-2">#{{ order.metadata.exterior_number }}</td>
              </tr>
              <tr>
                <td class="font-semibold uppercase text-gray-600">Horarios de entraga:</td>
                <td class="text-right pl-2">{{ order.metadata.delivery_hours }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <table class="">
          <tbody>
            <template v-if="order.payment_status === 'pending_payment'">
              <tr>
                <td><small class="mr-2 text-primary uppercase"><strong>Banco:</strong></small></td>
                <td class="text-right">{{ order.charges[0].payment_method.receiving_account_bank }}</td>
              </tr>
              <tr>
                <td><small class="mr-2 text-primary uppercase"><strong>Cuenta:</strong></small></td>
                <td class="text-right">{{ order.charges[0].payment_method.receiving_account_number }}</td>
              </tr>
            </template>
            <tr>
              <td><small class="mr-2 text-primary uppercase"><strong>IVA 16%:</strong></small></td>
              <td class="text-right text-lg">{{ currency(order.tax_lines[0].amount / 100) }}</td>
            </tr>
            <tr class="text-secondary">
              <td><small class="mr-2 uppercase text-xl"><strong>total:</strong></small></td>
              <td class="text-2xl text-right font-semibold">{{ currency(order.amount / 100) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <router-link
      class="text-white bg-primary w-64 py-3 rounded-full block text-center text-lg mt-8 mx-auto"
      :to="{ name: 'operations' }"
    >
      <r-icon type="check-circle" class="h-6 mr-3 inline-block" />
      Ir a Mis Rentas
    </router-link>
  </div>
</template>

<script lang="ts">
import { currency, date } from '@/filters';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { emptyCart } from '@/store/shoppingCart';
import { getConektaOrder, useConektaOrder } from '@/store/operations';

interface successQuery {
  checkout_id: string
  order_id: string
  payment_status: string
}

export default defineComponent({
  components: {},
  setup() {
    const query = useRoute().query as unknown as successQuery;
    const order = useConektaOrder();
    getConektaOrder(query.order_id);
    emptyCart();
    return { currency, date, query, order };
  },
  mounted() {
  },
});
</script>

<style></style>
