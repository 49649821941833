
import { currency, date } from '@/filters';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { emptyCart } from '@/store/shoppingCart';
import { getConektaOrder, useConektaOrder } from '@/store/operations';

interface successQuery {
  checkout_id: string
  order_id: string
  payment_status: string
}

export default defineComponent({
  components: {},
  setup() {
    const query = useRoute().query as unknown as successQuery;
    const order = useConektaOrder();
    getConektaOrder(query.order_id);
    emptyCart();
    return { currency, date, query, order };
  },
  mounted() {
  },
});
