import { readonly, ref } from 'vue';
import http from '@/http-common';
import { Data } from '@/types';
import { Operation, Operations, ConektaOrder } from './models/operations';
import { session } from './storage';

const operation = session<Operation|null>('operation');
const conektaOrder = ref<ConektaOrder|null>(null);
const creating = ref<boolean>(false);

const empty = (): Operations => ({
  data: [],
  links: {},
  meta: {},
});

const operations = ref(empty());

export const useCreating = () => readonly(creating);
export const useOperation = () => readonly(operation);
export const useOperations = () => readonly(operations);
export const useConektaOrder = () => readonly(conektaOrder);

export async function createCheckout(content: Data) {
  if (creating.value) return;
  creating.value = true;
  let response = null;
  try {
    response = await http.post('/api/carts/checkout', content);
    operation.value = response.data;
  } catch (error) {
    operation.value = null;
    throw error;
  } finally {
    creating.value = false;
  }
  return response;
}

export async function getOperations(page = 1) {
  try {
    const { data } = await http.get(`/api/operations?page=${page}`);
    return operations.value = data as Operations;
  } catch (e) {
    return operations.value = empty();
  }
}

export async function getConektaOrder(orderId: string) {
  try {
    const { data } = await http.get(`/api/user/conekta_order/${orderId}`);
    return conektaOrder.value = data.data as ConektaOrder;
  } catch (e) {
    return conektaOrder.value = null;
  }
}

export async function getOperation(uuid: string) {
  try {
    const { data } = await http.get(`/api/operations/${uuid}`);
    return operation.value = data as Operation;
  } catch (e) {
    return operation.value = null;
  }
}

export async function cancelOrder(uuid: string) {
  try {
    const { data } = await http.put(`/api/orders/${uuid}/cancel`);
    return operation.value = data.data as Operation;
  } catch (e) {
    return operation.value = null;
  }
}

